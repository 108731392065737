import Helper from "../polyfills/helper";
import Api from "../ajax/api";

const ClientSideSearch = (function () {

  function searchUrl(element) {
    return element.dataset.url;
  }

  function useHtml(element) {
    return Helper.hasDataAttribute(element, 'useHtml')
  }

  function useServerSearch(element) {
    return Helper.hasDataAttribute(element, 'url')
  }

  function filterElement(element) {
    let filter = element.querySelector('input[type=search]');
    if (!filter) {
      filter = element.querySelector('input[type=text]')
    }
    return filter;
  }

  function triggerElements(element){
    return document.querySelectorAll('.js-search-trigger')
  }

  function closeElement(element){
    return element.querySelector('.close')
  }

  function elementsContainerElement(element) {
    return element.querySelector('[data-elements-container]');
  }

  function placeholderElement(element) {
    return element.querySelector('[data-placeholder]');
  }

  function nothingFoundElement(element) {
    return element.querySelector('[data-no-results]');
  }


  //init
  function init() {
    const searchElements = Array.from(document.querySelectorAll('[data-search-container]'))
    searchElements.forEach(element => {
      initResourceFilter(element)
    });
  }

  function initResourceFilter(element) {
    filterElement(element).removeEventListener('keyup', filterResources);
    filterElement(element).addEventListener('keyup', filterResources);

    Array.from(triggerElements(element)).forEach(function(triggerElement) {
      triggerElement.removeEventListener('click', showSearch);
      triggerElement.addEventListener('click', showSearch);
    });

    closeElement(element).removeEventListener('click', closeSearch);
    closeElement(element).addEventListener('click', closeSearch);

    const filters = element.querySelectorAll('.search-option');
    Array.from(filters).forEach(function(filter) {
      filter.removeEventListener('click', setFilter)
      filter.addEventListener('click', setFilter)
    })
  }

  function filterResources(event) {
    const element = event.currentTarget.closest('[data-search-container]')

    nothingFoundElement(element).classList.add('hidden')

    const query = filterElement(element).value.toLowerCase();

    if (query == '' || query == null) {
      if (useServerSearch(element)) {
        serverSearch(query, element);
      } else {
        resetSearch(element);
      }
      return;
    }

    if (useServerSearch(element)) {
      if (query.length < 2) {
        return;
      }
      serverSearch(query, element);
    } else {
      clientSearch(query, element)
    }
  }

  function showSearch(event){
    const element = document.querySelector('[data-search-container]')
    element.classList.add('is--active')

    if (event.currentTarget.dataset.showCompany){
      setCompanyFilter()
    } else {
      setArticlesFilter();
    }
  }

  function closeSearch(event){
    const element = event.currentTarget.closest('[data-search-container]')
    element.classList.remove('is--active')
    toggleSearchOutput(element, false)
    filterElement(element).value = ''
  }

  function toggleSearchOutput(element, hasResults){
    if (hasResults) {
      document.body.style.overflow = 'hidden'
      element.querySelector('.search-results').classList.add('is--active')
    } else {

      document.body.style.overflow = ''
      element.querySelector('.search-results').classList.remove('is--active')
    }
  }

  function clientSearch(query, element) {
    const nothingFound = nothingFoundElement(element)

    const resourcesFound = filterResourcesClientSide(query, element);
    resourcesFound ? nothingFound.classList.add('hidden') : nothingFound.classList.remove('hidden');

    sendSearchedEvent(query, element);
  }

  function resetSearch(containerElement) {
    const resourceElements = Array.from(containerElement.querySelectorAll('[data-search]'));
    resourceElements.forEach(element => {
      element.classList.remove('search-hidden');
    });

    const searchGroupElements = Array.from(containerElement.querySelectorAll('[data-search-group]'));
    searchGroupElements.forEach(element => {
      element.classList.remove('search-hidden');
    });

    sendSearchedEvent('', containerElement);
  }

  function filterResourcesClientSide(query, containerElement) {
    // containerElement.find('.collapse').collapse('show') //show all elements if collapsed

    let resourcesFound = false

    const resourceElements = Array.from(containerElement.querySelectorAll('[data-search]'));
    resourceElements.forEach(element => {
      let resourceElementSearch = ""

      if (useHtml(containerElement)) {
        resourceElementSearch = element.innerHTML.toLowerCase();
      } else {
        resourceElementSearch = element.dataset.searchName.toLowerCase();
      }

      if (resourceElementSearch.match(query)) {
        element.classList.remove('search-hidden');
        resourcesFound = true
      } else {
        element.classList.add('search-hidden');
      }
    });

    const searchGroupElements = Array.from(containerElement.querySelectorAll('[data-search-group]'));
    searchGroupElements.forEach(searchGroupElement => {
      let searchElementsForGroup = Array.from(containerElement.querySelectorAll('[data-search="' + this.dataset.searchGroup + '"]'));
      let anyVisible = false;

      searchElementsForGroup.forEach(subElement => {
        if (!subElement.classList.contains('search-hidden')) {
          anyVisible = true;
        }
      });

      if (anyVisible) {
        searchGroupElement.classList.remove('search-hidden');
      } else {
        searchGroupElement.classList.add('search-hidden');
      }
    })
    return resourcesFound;
  }

  function serverSearch(query, containerElement) {
    let url = searchUrl(containerElement);
    if (url.includes('?')) {
      url = url + '&q=' + query;
    } else {
      url = url + '?q=' + query;
    }

    const filterElement = containerElement.querySelector('#kind')
    if (filterElement){
      url += "&kind=" + filterElement.value;
    }

    let timeout = Helper.hasDataAttribute(containerElement, 'timout') ? parseInt(containerElement.dataset.timout) : 0;

    clearTimeout(timeout);
    timeout = setTimeout(function () {

      elementsContainerElement(containerElement).innerHTML = '';
      placeholderElement(containerElement).classList.remove('hidden')

      const request = new Request(url);
      toggleSearchOutput(containerElement, true)
      Api.callApi(request, 'GET')
        .then((result) => {
          placeholderElement(containerElement).classList.add('hidden')
          elementsContainerElement(containerElement).innerHTML = result

          // do a client search after the server search
          window.setTimeout(function () {
            if (result == '') {
              toggleSearchOutput(containerElement, false)
              nothingFoundElement(containerElement).classList.remove('hidden')
            } else {
              toggleSearchOutput(containerElement, true)
              // dit hoeft niet als je server side searched
              // clientSearch(query, containerElement)
            }
          }, 10);
        }).catch(ex => {
        window.location.reload();
      })
    }, 800);
    containerElement.dataset.timout = timeout + ''
  }

  function sendSearchedEvent(search, element) {
    Helper.dispatchEvent('onSearched', {
      search: search
    })
  }

  function setFilter(event){
    const containerElement = event.currentTarget.closest('[data-search-container]')
    containerElement.querySelectorAll('.search-option').forEach(function(option){
      option.classList.remove('is--active');
    })

    containerElement.querySelector('#kind').value = event.currentTarget.dataset.searchOption
    event.currentTarget.classList.add('is--active')

    const filterElement = containerElement.querySelector('#filter');
    if (filterElement && filterElement.value != ''){
      filterResources(event);
    }
  }

  function setCompanyFilter(){
    const containerElement = document.querySelector('[data-search-container]')

    containerElement.querySelectorAll('.search-option').forEach(function(option){
      option.classList.remove('is--active');
    })

    containerElement.querySelector('#kind').value = 'company'
    containerElement.querySelector('[data-search-option="company"]').classList.add('is--active')

    const filterElement = containerElement.querySelector('#filter');
    if (filterElement && filterElement.value != ''){
      filterResources({currentTarget: filterElement});
    }
  }

  function setArticlesFilter(){
    const containerElement = document.querySelector('[data-search-container]')

    containerElement.querySelectorAll('.search-option').forEach(function(option){
      option.classList.remove('is--active');
    })

    containerElement.querySelector('#kind').value = 'article'
    containerElement.querySelector('[data-search-option="article"]').classList.add('is--active')

    const filterElement = containerElement.querySelector('#filter');
    if (filterElement && filterElement.value != ''){
      filterResources({currentTarget: filterElement});
    }
  }

  return {
    init: init
  }
})();

ClientSideSearch.init()

export default ClientSideSearch
