import Form from "../form/form";
import Helper from "../polyfills/helper";
import UrlHelper from "../polyfills/url_helper";
import Api from './api'

const AjaxHandlers = (function(){

  function init() {
    initAsync();
  }

  function initAsync(){
    Helper.handleCustomEvent('ajax::refresh', initAsync)
    initHandlers()
    initPostBackControls()
  }

  function initPostBackControls(){
    Array.from(document.querySelectorAll('[data-async-trigger]')).forEach(element => {
      if (element.type == 'text' || element.tagName == 'TEXTAREA'){
        element.removeEventListener('keyUp', handleAsyncControl)
        element.addEventListener('keyUp', handleAsyncControl)
      } else {
        element.removeEventListener('change', handleAsyncControl)
        element.addEventListener('change', handleAsyncControl)
      }
    })
  }

  function handleAsyncControl(event){
    const element = event.currentTarget;
    const form = element.closest('[data-remote]')
    if (form){
      Api.submit(form)
    }
  }

  function initHandlers(){
    const asyncContainers = document.querySelectorAll('[data-remote]');
    if (Helper.isEmpty(asyncContainers)) { return; }

    Array.from(asyncContainers).forEach((asyncContainer) => {
      checkLoadInitial(asyncContainer);
    })

    initializeAjaxCallback()
  }

  function checkLoadInitial(element){
    if (element.dataset.loadInitial == '1') {
      element.dataset.loadInitial = '0'
      Api.submit(element)
    }
  }

  function initializeAjaxCallback(){
    Helper.addEventListenerAll('[data-remote]','ajax:success', onAjaxSuccess)
    Helper.addEventListenerAll('[data-remote]','ajax:complete', onAjaxComplete)
    Helper.addEventListenerAll('[data-remote]','ajax:beforeSend', updateCurrentLocationWithGetUrl)
  }

  function onAjaxSuccess(event){
    debugger
    let target = event.currentTarget;
    if (event.currentTarget.dataset.target){
      target = document.querySelector(event.currentTarget.dataset.target)
    }

    if (event.detail[2].status === 200) {
      const xhr = event.detail[2];
      const stringData = xhr.response;
      if (stringData.includes("Turbolinks")) {
        return;
      }

      if (!xhr.getResponseHeader('Content-Type').includes('application/json')) {
        // Html content
        Form.clearErrors(target);
        Api.handleSuccess(target, 'text', xhr.responseText)
      } else {
        //JSON content
        const data = event.detail[0]
        if (!Helper.isEmptyObject(data.errors)) {
          Form.handleErrors(target, data.errors)
        } else {
          Form.clearErrors(target);
        }

        if (data.html && data.html.length > 1) {
          Api.handleSuccess(event.currentTarget, 'text', data.html)
        }
      }
    }

    Api.fireAjaxRefreshEvent();
  }

  function onAjaxComplete(event){
    debugger
    const target = event.currentTarget;
    const responseCode = event.detail[0].status
    const responseText = event.detail[0].responseText;

    if (responseCode == 400 && responseText) {
      const responseData = JSON.parse(responseText)

      Api.handleClientError(responseData.errors)
      Form.handleErrors(target, responseData.errors)
      Api.fireAjaxRefreshEvent();
    }
    else if (responseCode === 302 || responseCode === 301) {
      let redirect = event.detail[0].getResponseHeader('X-Ajax-Redirect-Url');
      Api.handleRedirect(redirect)
    }
  }

  function updateCurrentLocationWithGetUrl(xhr) {
    const url = UrlHelper.filterEmptyUrlParameters(xhr.detail[1].url)
    //append querystring etc for search
    // todo waarom?
    // window.history.replaceState(null, document.title, url)
  }

  return {
    init: init,
  }
})();

export default AjaxHandlers
