const UrlHelper = (function () {
  function filterEmptyUrlParameters(url) {
    return url.replace(/[^?=&]+=(&|$)/g,"").replace(/&$/,"");
  }

  function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);

    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  return {
    filterEmptyUrlParameters: filterEmptyUrlParameters,
    getParameterByName: getParameterByName,
  }
})();

export default UrlHelper;
