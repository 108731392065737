import mapModule from './map-module'
import Helper from "../polyfills/helper";
import CookieStorage from "../polyfills/cookie";
import Storage from "../polyfills/storage";

(function () {
  let search = {};
  let map = {};
  let latitude;
  let longitude;
  let version = '1.03';

  function checkVersion(version){
    const storedVersion = Storage.getItem('location_manager_version');
    if (storedVersion !== version) {
      CookieStorage.setCookie('coordinates', '');
      Storage.setItem('location_manager_version', version);
    }
  }

  function initMarker(){
    console.log('%c init markers', 'background: #888; color: #fff');
    const toggleUserLocation = document.getElementById('map_marker');
    if (toggleUserLocation) {
      console.log('%c enabling toggle user location', 'background: #888; color: #fff');
      toggleUserLocation.addEventListener('click', (event) => {
        askForLocation((latitude, longitude) => {
          console.log('%c after askForLocation', 'background: #888; color: #fff');
          if (latitude && longitude) {
            mapModule.setCenterToLocation({latitude: latitude, longitude: longitude});
            // window.location.reload()
          }
        })

        toggleUserLocation.classList.add('map-marker--active');
      });

      if (latitude && longitude){
        toggleUserLocation.classList.add('map-marker--active');
      }
    }
  }

  function initMap(){
    if (document.getElementById('map') === null) { return; }

    console.log('%c init map', 'background: #888; color: #fff');
    const mapsFilterElement = document.getElementById('maps-filter');
    let locationsUrl = '/locations'

    let queryString = ''
    if (mapsFilterElement) {
      queryString = '?' + mapsFilterElement.value
    }

    map = mapModule.init('/maps/token', locationsUrl + queryString, 'map', annotationCallback, function(){
      console.log('%c after init maps', 'background: #888; color: #fff');
      initMarker();
      console.log('%c checking latitude/ longitude', 'background: #888; color: #fff');
      if (latitude && longitude){
        const coordinate = new mapkit.Coordinate(latitude, longitude)
        const radius = 20 * 1000 // The radius is in meters.
        const circle = new mapkit.CircleOverlay(coordinate, radius);
        // circle.data = { population: stat.population };
        circle.style = new mapkit.Style({
          lineWidth: 2,
          strokeColor: "#999",
          fillColor: "#008337"
        });
        console.log('%c adding overlay', 'background: #888; color: #fff');
        map.addOverlays([circle]);

        window.setTimeout(()=>{
          console.log('%c setting to user location', 'background: #888; color: #fff');
          mapModule.setCenterToLocation({latitude: latitude, longitude: longitude});
        }, 100)
      } else {
        const searchInput = document.getElementById('maps_search');
        if (!searchInput || !searchInput.value) {
          console.log('%c setting to nederland', 'background: #888; color: #fff');
          window.setTimeout(function () {
            searchValue("nederland", 3.0);
          }, 100)
        }
      }
    });
  }

  function initUserLocation() {
    console.log('%c init user locations', 'background: #888; color: #fff');
    const cachedLocation = CookieStorage.getCookie('coordinates');

    if (cachedLocation){
      console.log('%c found cached user locations', 'background: #888; color: #fff');
      latitude = parseFloat(cachedLocation.split('_')[0])
      longitude = parseFloat(cachedLocation.split('_')[1])
    }
  }

  function initAskForLocation(){
    console.log('%c init askForLocation', 'background: #888; color: #fff');
    const element = document.getElementById('ask_location_element');
    if (!element) { return; }

    if (longitude && latitude){
      console.log('%c already have latitude and longitude, hiding element', 'background: #888; color: #fff');
      element.style.display = 'none'
    } else {
      console.log('%c showing element', 'background: #888; color: #fff');
      element.classList.add('show')
      document.getElementById('ask_location').addEventListener('click', (event)=> {
        askForLocation((latitude, longitude) => {
          element.style.display = 'none'
          window.location.reload()
        })
      })
    }
  }

  function askForLocation(callback){
    console.log('%c asking user for location', 'background: #888; color: #fff');
    if (latitude && longitude){
      callback(latitude, longitude)
      return
    }

    navigator.geolocation.getCurrentPosition((position)=>{
      console.log('%c user responded to ask for location', 'background: #888; color: #fff');
      CookieStorage.setCookie('coordinates', position.coords.latitude + '_' + position.coords.longitude);
      latitude = position.coords.latitude;
      longitude = position.coords.longitude;
      callback(latitude, longitude)
    })
  }

  function initArticles(){
    console.log('%c init Articles', 'background: #888; color: #fff');
    if (longitude && latitude){
      const locationElements = document.querySelectorAll('[data-latitude]');
      locationElements.forEach((locationElement)=>{
        const itemLatitude = parseFloat(locationElement.dataset.latitude);
        const itemLongitude = parseFloat(locationElement.dataset.longitude);

        let distance = mapModule.getDistanceFromLatLonInKm(latitude, longitude, itemLatitude, itemLongitude);
        if (parseInt(distance) == 0){
          locationElement.querySelector('.location_km').innerHTML = "< 1 km";
        } else if (parseInt(distance) < 100) {
          locationElement.querySelector('.location_km').innerHTML = parseInt(distance) + " km";
        }
        locationElement.classList.add('is--visible');
      })
    }
  }

  function initVacancies(){

  }

  function init(){
    initUserLocation();
    initAskForLocation();
    initMap()
    initArticles();

    Helper.handleCustomEvent('ajax::refresh', init)
  }

  window.addEventListener("load", function () { //use load! event. Mapkit is async and could be loaded after DOMContentLoaded is fired.
    init();

    const searchInput = document.getElementById('maps_search');
    if (searchInput === undefined || searchInput == null) { return; }

    let timerId;
    search = mapModule.initSearch();

    searchInput.addEventListener('keydown', (event) => {
      if (event.keyCode == 13){
        event.preventDefault();
        return false;
      }
    });

    searchInput.addEventListener('keyup', (event) => {
      clearTimeout(timerId);
      if (event.keyCode === 13) {
        searchValue(searchInput.value);
        searchInput.blur();
      } else {
        const searchedValue = searchInput.value;
        if (searchedValue.length < 3) { return; }
        timerId = setTimeout(function () {
          searchValue(searchedValue);
        }, 1000);
      }
    });
  });

  function searchValue(searchedValue, zoom) {
    if (searchedValue.length === 0) {
      return;
    }

    mapModule.searchForLocations(search, searchedValue, zoom, function (result) {
      if (result.success) {
        // document.getElementById('map_marker').classList.add('map-marker--active');
      } else {
        // document.getElementById('map_marker').classList.remove('map-marker--active');
      }
    });
  }

  function annotationCallback(annotation) {
    console.log('%c making annotation', 'background: #222; color: #bada55');
    const div = document.createElement("div");
    div.className = "location__annotation";

    if (annotation.location.highlight) {
      const bieristaSection = div.appendChild(document.createElement("section"));
      const image = bieristaSection.appendChild(document.createElement("img"));
      image.src = annotation.location.annotation_image_url;
      image.style.width = '100%';
      image.style.position = 'static';
      image.style.objectPosition = 'center';
      image.style.objectFit = 'contain';
      image.style.height = 'auto';
    }

    const section = div.appendChild(document.createElement("section"));
    const title = section.appendChild(document.createElement("div"));
    title.classList.add('location__annotation__header');
    title.textContent = annotation.location.title;

    const a = section.appendChild(document.createElement("a"));
    a.href = annotation.location.url;
    a.classList.add('location__annotation__link');
    a.textContent = "Meer informatie";
    return div;
  }
})();
