import Helper from "../../polyfills/helper";

const Boolean = (function () {
  function init() {
    hookupBooleans();
  }

  function hookupBooleans() {
    const booleans = document.querySelectorAll('input[type="checkbox"], input[type="radio"]')
    Array.from(booleans).forEach(element => {
      element.removeEventListener('change', onToggleCheckbox)
      element.addEventListener('change', onToggleCheckbox)
      toggleCheckbox(element, true);
    })
  }

  function onToggleCheckbox(event) {
    toggleCheckbox(event.currentTarget)
  }

  function toggleCheckbox(element, firstPass = false) {
    const parent = element.closest('.boolean_with_image')
    if (Helper.isNull(parent)) {
      return;
    }

    if (element.checked) {
      parent.classList.add('checked')
    } else {
      parent.classList.remove('checked')
    }

    if (element.type == 'radio' && !firstPass) {
      const otherElements = document.querySelectorAll('input[name="' + element.name + '"]')
      Array.from(otherElements).forEach(otherElement => {
        if (otherElement != element) {
          if (element.checked) {
            otherElement.closest('.boolean_with_image').classList.remove('checked')
          } else {
            otherElement.closest('.boolean_with_image').classList.add('checked')
          }
        }
      });
    }
  }

  return {
    init: init,
  }
})();

export default Boolean;
