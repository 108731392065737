// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import '../polyfills/index'

// import '../../stylesheets/application.scss'

import Rails from "@rails/ujs";

Rails.start()
window.Rails = Rails

import '../ajax/index'
import '../elements/index'
import '../elements/article'
import '../elements/user-article'
import '../elements/banner'
import '../elements/full-screen-image'
import '../models/index'
import '../elements/client-side-search'
// import '../table/index'
import '../form/index'
// import '../settings/index'
import '../notifications/index'
// import '../utils/index'
import '../cookie_banner'
import '../maps/initializer'
