import Helper from "../polyfills/helper";

const Modal = (function () {

  function init() {
    Helper.handleCustomEvent('ajax::refresh', init)

    const modalTogglers = Array.from(document.querySelectorAll('[data-toggle="modal"]'))
    modalTogglers.forEach(element => {
      element.removeEventListener('click', onModalTogglerClicked)
      element.addEventListener('click', onModalTogglerClicked)
    })

    const modalDismissers = Array.from(document.querySelectorAll('[data-dismiss="modal"]'))
    modalDismissers.forEach(element => {
      element.removeEventListener('click', onModalDismiss)
      element.addEventListener('click', onModalDismiss)
    })

    handleShowInitial();
  }

  function onModalTogglerClicked(event) {
    const element = event.currentTarget;
    modalTogglerClicked(element);
  }

  function modalTogglerClicked(element) {
    const target = document.querySelector(element.dataset.bsTarget);
    var targetSelector = element.dataset.bsTarget;

    target.classList.toggle('show');

    if (target.classList.contains('show')) {
      document.querySelectorAll('[data-bs-target="' + targetSelector + '"]').forEach(function(toggler){
        toggler.classList.add('expanded')
      });
      // document.body.style.overflow = 'hidden';
      Helper.dispatchEvent('modal::showing', {
        modal: target
      });
    } else {
      document.querySelectorAll('[data-bs-target="' + targetSelector + '"]').forEach(function(toggler){
        toggler.classList.remove('expanded')
      });
      document.body.style.overflow = null;
    }
  }

  function onModalDismiss(event) {
    const element = event.currentTarget;
    modalDismiss(element);
  }

  function modalDismiss(element) {
    const target = element.closest('.modal');
    target.classList.remove('show')
    document.body.style.overflow = null;
    Helper.dispatchEvent('modal::hiding', {
      modal: target
    });
  }

  function handleShowInitial() {
    const showInitialElement = document.querySelector('.modal.show--initial')
    if (showInitialElement) {
      document.body.style.overflow = 'hidden';
    }
  }

  return {
    init: init
  }

})();

export default Modal;
