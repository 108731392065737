import Helper from "../polyfills/helper";
import Rails from "@rails/ujs";
import Api from "./api";

const RemoteModals = (function(){

  function init(){
    Helper.handleCustomEvent('ajax::refresh', init)
    Helper.handleCustomEvent('modal::showing', onModalShowing)
    Helper.handleCustomEvent('modal::hiding', onModalHidden)
  }

  function onModalShowing(event){
    const form = event.detail.payload.modal.querySelector('[data-load-on-modal]')
    if (Helper.isNull(form)) { return; }

    Api.submit(form)
  }

  function onModalHidden(event){
    const form = event.detail.payload.modal.querySelector('[data-load-on-modal]')
    if (Helper.isNull(form)) { return; }

    form.innerHTML = ''
  }

  return {
    init: init
  }
})();

export default RemoteModals;
