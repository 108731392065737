const Cloner = (function(){

  const init = function(){
    hookUpCloners()
    hookUpRemovers()
  }

  const hookUpCloners = function(){
    Array.from(document.querySelectorAll('[data-clone-trigger]')).forEach(element => {
      element.removeEventListener('click', onCloneElementTriggered)
      element.addEventListener('click', onCloneElementTriggered)
    })
  }

  const onCloneElementTriggered = function(event){
    const element = event.currentTarget;
    const targets = document.querySelectorAll('[data-id="' + element.dataset.cloneTrigger + '"]')
    if (targets.length == 0) { return; }

    const target = targets[targets.length -1]
    const clone = cloneElement(target)
    insertAfter(clone, target)
    hookUpRemovers()
    const refreshEvent = new CustomEvent('custom::new_element', {});
    document.dispatchEvent(refreshEvent);
  }

  const cloneElement = function(element){
    const newElement = document.createElement('div')
    newElement.dataset.clone = '1'
    newElement.dataset.id = element.dataset.id
    newElement.dataset.copy = ''
    newElement.innerHTML = element.innerHTML;

    let maxId = getNewId(newElement)

    newElement.querySelectorAll('input').forEach(element => {
      const valueId = element.name.replace(/\D/g, "")
      if (valueId) {
        element.name = element.name.replace(/[0-9]/g, maxId)
        element.id = element.id.replace(/[0-9]/g, maxId)
        element.value = ''
      }
    })

    newElement.querySelectorAll('label').forEach(element => {
      const forAttribute = element.getAttribute('for')
      if (forAttribute) {
        const forId = element.getAttribute('for').replace(/\D/g, "")
        if (forId) {
          element.setAttribute('for', element.getAttribute('for').replace(/[0-9]/g, maxId))
        }
      }
    })

    newElement.querySelectorAll('[data-clone-increment]').forEach(element => {
      const text = element.innerText.replace(/\D/g, "")
      if (text) {
        element.innerText = element.innerText.replace(/[0-9]/g, maxId + 1)
      }
    })

    return newElement;
  }

  function hookUpRemovers(){
    const removeElements = Array.from(document.querySelectorAll('[data-remove]'))
    removeElements.forEach(removeElement => {
      removeElement.removeEventListener('click', onRemoveElementClicked)
      removeElement.addEventListener('click', onRemoveElementClicked)

      const clone = removeElement.closest('[data-copy]')
      if (clone){
        removeElement.classList.remove('is-hidden')
      }
    });
  }

  function onRemoveElementClicked(event){
    const clone = event.currentTarget.closest('[data-clone]')
    removeElement(clone)
  }

  function removeElement(element){
    element.remove()
  }

  function getNewId(element){
    let maxId = 0
    element.querySelectorAll('input').forEach(element => {
      const valueId = element.name.replace(/\D/g, "")
      if (valueId && parseInt(valueId) > maxId){
        maxId = parseInt(valueId)
      }
    })

    maxId += 1;
    return maxId;
  }

  const createElementFromTemplate = function(data, template) {
    const clone = document.importNode(template.content, true);

    const entries = Object.entries(data);
    entries.forEach(function(entry){
      setProperty(entry[0], entry[1], clone)
    });
    return clone;
  };

  const setProperty = function(property, value, clone, prefix = '') {
    if (value == null) { return;}

    const item = clone.querySelector('[data-property="' + prefix + property + '"]')
    if (item !== undefined && item !== null) {
      if (item.dataset.attribute === 'class') {
        let correctedValue = value.toLowerCase();
        correctedValue = correctedValue.split(' ').join('-');
        item.classList.add(correctedValue);
      }
      else if (item.nodeName === "IMG") {
        item.setAttribute('src', value)
      } else {
        item.innerText = value;
      }
    }
  };

  function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  return {
    createElementFromTemplate: createElementFromTemplate,
    cloneElement: cloneElement,
    init: init
  }
})();

export default Cloner;
