import Notification from "./notification";
import Helper from "../polyfills/helper";

const PushNotificationListener = (function(){

  function init() {
    Helper.handleCustomEvent('notifications::push_notification_received', showNotification)
    Helper.handleCustomEvent('notifications::push_notification_enabled', enableNotifications)
    Helper.handleCustomEvent('notifications::push_notification_disabled', disableNotifications)
  }

  function showNotification(data) {
    Notification.createToast(data.detail.payload.notification.title, data.detail.payload.notification.body, data.detail.payload.data)
  }

  function enableNotifications(element) {

  }
  function disableNotifications(element) {

  }
  return {
    init: init
  }
})();

export default PushNotificationListener;
