import Helper from "../polyfills/helper";

const Toasts = (function () {

  function init() {
    Helper.handleCustomEvent('ajax::refresh', init)
    Helper.handleCustomEvent('toast::created', initNewToast)

    const toastElementList = Array.from(document.querySelectorAll('.toast'))
    toastElementList.forEach((toastEl) => {
      initToast(toastEl)
    })

    const toastDismissElements = Array.from(document.querySelectorAll('[data-dismiss="toast"]'))
    toastDismissElements.forEach((element) => {
      element.removeEventListener('click', onToastDismiss)
      element.addEventListener('click', onToastDismiss)
    })
  }

  function initNewToast(data) {
    initToast(data.detail.payload)
  }

  function initToast(toastEl) {
    if (Helper.hasDataAttribute(toastEl, 'show')) {
      toastEl.classList.add('show')
    }

    if (Helper.hasDataAttribute(toastEl, 'timout')) {
      toastEl.classList.add('show')
      window.setTimeout(() => {
        toastEl.remove();
      }, toastEl.dataset.timout)
    }
  }

  function onToastDismiss(event) {
    const target = event.currentTarget;
    toastDismiss(target);
  }

  function toastDismiss(element) {
    const toastEl = element.closest('.toast')
    toastEl.remove();
  }

  return {
    init: init
  }
})();

export default Toasts;
