import Helper from "../polyfills/helper";
import Cloner from "../elements/clone";

const Notification = (function () {

  function init() {
    Helper.handleCustomEvent('ajax::refresh', init)
    const notificationElements = Array.from(document.querySelectorAll('.notification'))
    notificationElements.forEach((element) => {
      const focusElement = element.querySelector('#focus')
      if (focusElement) {
        focusElement.focus();
        const modalBody = focusElement.closest('.modal-body')
        if (modalBody) {
          modalBody.scrollTop = 0;
        }
        focusElement.scrollIntoView();
      }
      if (Helper.hasDataAttribute(element, 'timout')) {
        window.setTimeout(() => {
          element.remove();
        }, element.dataset.timout)
      }
    })
  }

  function createNotice(target, templateKey, title, text = '', data = null) {
    const noticeContainer = document.querySelector(target)
    if (Helper.isEmpty(noticeContainer)) {
      return;
    }

    const template = noticeContainer.querySelector(templateKey)

    const sanitizedData = {
      title: title,
      text: text,
    }

    if (data) {
      sanitizedData.payload_text = data.text
      sanitizedData.payload_url = data.url
    }
    const element = Cloner.createElementFromTemplate(sanitizedData, template)
    noticeContainer.appendChild(element)
    window.setTimeout(_ => {
      const firstChild = noticeContainer.querySelector('div')
      if (firstChild) {
        noticeContainer.removeChild(firstChild)
      }
    }, 8000);
  }

  function createToast(title, text = '', data = null) {
    createToastElement(title, text, data)
  }

  function createAlert(title, text = '', data = null) {
    const toast = createToastElement(title, text, data)
    toast.classList.add('toast-danger')
  }

  function createToastElement(title, text = '', data = null) {
    const toastContainer = document.querySelector('[data-toast-container]')
    const toast = Helper.createElement('div', 'toast show', null, toastContainer, {'showInitial': '1'})

    const toastIconWrapper = Helper.createElement('div', 'toast-icon', null, toast)
    Helper.createElement('span', 'material-icons-outlined', 'notifications',toastIconWrapper )

    const toastText = Helper.createElement('div', 'toast-text', null, toast)
    Helper.createElement('strong', '', title, toastText)

    const date = new Date;
    const minutes = date.getMinutes();
    const hour = date.getHours();
    Helper.createElement('span', 'time', "" + hour + " : " + minutes, toastText)
    Helper.createElement('span', '', text, toastText)

    const toastClose = Helper.createElement('div', 'btn-close', null, toast, {'dismiss': 'toast'})
    Helper.createElement('span', 'material-icons-outlined', 'close',toastClose )

    Helper.dispatchEvent('toast::created', toast)

    window.getComputedStyle(toast).transform //force layout
    window.setTimeout(_ => {
      toastContainer.removeChild(toast)
    }, 8000);

    return toast;
  }

  return {
    init: init,
    createToast: createToast,
    createAlert: createAlert,
    createNotice: createNotice
  }

})();

export default Notification
