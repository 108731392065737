var fullScreenImage = (function () {

  function init() {
    document.querySelectorAll('.article-container .text img ').forEach(function(image){
      if (image.closest('.image-full-screen')){
        return;
      }
      const imageCopy = document.createElement('img')
      imageCopy.src = image.src;
      imageCopy.className = image.className;

      const imageWrapper = document.createElement('div')
      imageWrapper.className = 'image-full-screen'
      imageWrapper.appendChild(imageCopy);

      const closeElement = document.createElement('div')
      closeElement.className = 'icon material-icons-outlined close-icon';
      closeElement.innerHTML = 'close'
      imageWrapper.appendChild(closeElement)

      image.replaceWith(imageWrapper)

      closeElement.addEventListener('click', (event)=> {
        imageWrapper.classList.remove('full-screen')
      })

      imageCopy.addEventListener('click', (event)=>{
        imageWrapper.classList.add('full-screen')
      })
    })
  }

  document.addEventListener('DOMContentLoaded', function () {
    init();
  })

  return {
    init: init
  }
})();

window.fullScreenImage = fullScreenImage;
