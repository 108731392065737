import ErrorHandler from "../polyfills/errors";
import Helper from "../polyfills/helper";
import Cloner from "../elements/clone";

const Api = (function () {
  const headersHash = {
    'pragma': 'no-cache', //No caching!
    'cache-control': 'no-cache',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  }

  function submit(element) {
    showPlaceHolder(element)

    if (element.dataset.remote && element.tagName == 'FORM') {
      //just use rails
      Rails.fire(element, "submit");
    } else if (element.tagName == 'FORM') {
      element.submit();
    } else {
      const method = element.getAttribute('method')
      const url = element.getAttribute('url')
      const request = new Request(url);

      return callApi(request, method).then(data => {
        handleSuccess(element, 'text', data)
        fireAjaxRefreshEvent()
      }).catch(ex => {
        ErrorHandler.captureException(ex)
        fireAjaxRefreshEvent()
      })
    }
  }

  function callApi(request, method, data, _headers = {}) {
    return new Promise((resolve, reject) => {

      Object.assign(headersHash, _headers);
      if (headersHash['Content-Type'] == "application/x-www-form-urlencoded") {
        delete headersHash['Content-Type']
      }

      const initObject = {
        method: method,
        headers: new Headers(headersHash),
        credentials: 'include'
      };

      if (method == "POST" || method == "PATCH" || method == "DELETE") {
        if (headersHash['Content-Type'] == 'application/json') {
          data.authenticity_token = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
          initObject.body = JSON.stringify(data);
        } else {
          data.append('authenticity_token', document.head.querySelector('meta[name="csrf-token"]').getAttribute('content'))
          initObject.body = data;
        }
      }

      let status;

      fetch(request, initObject)
        .then(function (response) {
          status = response.status
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
          } else {
            return response.text();
          }
        })
        .then(function (data) {
          if (status < 300) {
            resolve(data);
          } else if (data.error) {
            reject(data); // handle error in caller
          } else {
            if (status !== 404 && status !== 422) { // 422 can be caused by not validating authenticity_token (ie. session expired)
              ErrorHandler.captureException(new Error("Status: " + status + " rejecting data"));
            }
            reject(data)
          }
        })
        .catch(function (error) {
          ErrorHandler.captureException(error);
          reject(error)
        });
    });
  }

  function showPlaceHolder(element) {
    const placeholderSelector = element.getAttribute('placeholder')
    if (Helper.isEmpty(placeholderSelector)) {
      return;
    }

    const placeholderTemplate = document.querySelector(placeholderSelector)
    if (Helper.isNull(placeholderTemplate)) {
      return;
    }

    element.innerHTML = "";
    const clone = Cloner.createElementFromTemplate({}, placeholderTemplate);
    element.appendChild(clone);
  }

  function handleSuccess(target, kind, data) {
    if (kind == 'text') {
      target.innerHTML = data

      fireAjaxSuccessEvent({
        type: 'text',
        currentTarget: target,
        data: data
      })
    } else {

    }
  }

  function handleClientError(response) {
    fireAjaxErrorEvent(response.errors)
  }

  function handleRedirect(url) {
    if (window.location.href.replace('#', '') == window.location.origin + url) {
      location.reload();
    } else {
      window.location.href = window.location.origin + url;
    }
  }

  function fireAjaxSuccessEvent(data) {
    Helper.dispatchEvent('ajax::success', data)
  }

  function fireAjaxErrorEvent(errors) {
    Helper.dispatchEvent('ajax::bad_request', errors)
  }

  function fireAjaxRefreshEvent() {
    Helper.dispatchEvent('ajax::refresh')
  }

  function sleep(seconds) {
    var e = new Date().getTime() + (seconds * 1000);
    while (new Date().getTime() <= e) {
    }
  }

  return {
    callApi: callApi,
    submit: submit,
    handleSuccess: handleSuccess,
    handleRedirect: handleRedirect,
    handleClientError: handleClientError,
    fireAjaxRefreshEvent: fireAjaxRefreshEvent
  }
})();

export default Api;
