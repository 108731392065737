//Requires polyfill for fetch api
//Map should have id of map
const mapModule = (function () {
  'use strict';

  let SEARCH_THRESHOLD = 3; //radius for when search is used
  let map = {};
  let cachedLocations = [];
  let search = {};
  let annotationCallout = null;

  let headers = new Headers();
  headers.append('pragma', 'no-cache'); //No caching!
  headers.append('cache-control', 'no-cache');
  const initialization_object = {
    method: 'GET',
    headers: headers
  };

  const init = function (tokenUrl, markersUrl, mapSelector, annotationCalloutCallback, afterMarkerCallback) {
    if (document.getElementById(mapSelector) === null) { return null; }

    initMapKit(tokenUrl, mapSelector);
    initMap(mapSelector);
    if (annotationCalloutCallback != null) {
      setAnnotationCallout(annotationCalloutCallback);
    }
    addMarkers(markersUrl, afterMarkerCallback);
    return map;
  };

  const initMapKit = function (tokenUrl, mapSelector) {
    mapkit.init({
      authorizationCallback: function (done) { //this is called by mapkit when new token is required
        var request = new Request(tokenUrl);
        fetch(request, initialization_object)
          .then(function (response) {
            return response.text();
          })
          .then(function (text) {
            done(text); //call the original done function
          })
          .catch(function (error) {
            console.error(error);
            document.getElementById(mapSelector).classList.add('unavailable');
          });
      }
    });
  };

  const initMap = function (mapSelector) {
    map = new mapkit.Map(mapSelector, {
      showsMapTypeControl: false,
      isScrollEnabled: true,
      isRotationEnabled: true,
      showsZoomControl: true,
      // showsCompass: mapkit.FeatureVisibility.Visible
    });

    if (!map || Object.keys(map).length == 0) {
      return;
    }

    map.addEventListener('deselect', function(event){
      const existingAnnotation = document.querySelector('.location__annotation.is--fixed')
      if (existingAnnotation){
        existingAnnotation.remove();
      }
    });

    map.addEventListener('select', function(event){
      if (!event.annotation) { return; }

      const annotations = event.annotation.memberAnnotations;
      let div = document.querySelector('.location__annotation.is--fixed')
      if (div){
        div.innerHTML = ''
      } else {
        div = document.createElement("div");
      }
      div.className = "location__annotation is--fixed";

      const section = div.appendChild(document.createElement("section"));
      const title = section.appendChild(document.createElement("div"));
      title.classList.add('location__annotation__header');
      title.textContent = "Meerdere locaties";

      const list = document.createElement('ul');
      annotations.forEach((annotation)=> {
        const li = document.createElement('li')
        const a = document.createElement("a");
        a.href = annotation.location.url;
        a.classList.add('location__annotation__link');
        a.textContent = annotation.location.title;
        li.appendChild(a)
        list.appendChild(li)
      })
      section.appendChild(list)
      div.appendChild(section)
      document.getElementById('map').appendChild(div);
    })
  };

  const setAnnotationCallout = function (callback) {
    annotationCallout = {
      calloutElementForAnnotation: function (annotation) {
        return callback(annotation);
      },

      calloutAnchorOffsetForAnnotation: function (annotation, element) {
        return new DOMPoint(0, 0);
      },

      calloutAppearanceAnimationForAnnotation: function (annotation) {
        return "scale-and-fadein .4s 0 1 normal cubic-bezier(0.4, 0, 0, 1.5)";
      }
    };
  };

  const addMarkers = function (markersUrl, afterMarkerCallback) {
    const request = new Request(markersUrl);
    fetch(request, initialization_object)
      .then(function (response) {
        return response.json();
      })
      .then(function (locations) {
        cachedLocations = locations;
        var markers = _convertToMarkers(locations);
        console.log('%c adding markers', 'background: #222; color: #bada55');
        map.showItems(markers);
        _setCenter(locations); //set center last
        afterMarkerCallback();
      })
      .catch(function (error) {
        console.error(error);
        afterMarkerCallback();
      });
  };

  const _convertToMarkers = function (locations) {
    const MarkerAnnotation = mapkit.MarkerAnnotation;
    const ImageAnnotation = mapkit.ImageAnnotation;
    //clickAnnotation

    let annotations = [];
    for (let i = 0; i < locations.length; i++) {
      if (locations[i].type !== 'center') {
        let coordinates = new mapkit.Coordinate(locations[i].latitude, locations[i].longitude);

        let options = _createOptions(locations[i]);
        let annotation = location.marker_type === 'annotation' ?
          new MarkerAnnotation(coordinates, options) :
          new ImageAnnotation(coordinates, options);
        annotation.location = locations[i];
        annotations.push(annotation);
      }
    }
    return annotations;
  };

  function _createOptions(location) {
    if (location.marker_type === 'annotation') {
      return {
        title: location.title,
        subtitle: location.subTitle,
        color: location.color,
        glyphColor: location.glyphColor,
        glyphImage: location.glyphImage,
        selectedGlyphImage: location.selectedGlyphImage,
        clusteringIdentifier: 'cluster_id',
        collisionMode: 'rectangle',
        displayPriority: 1,
        callout: annotationCallout
      };
    } else {
      return {
        title: location.title,
        subtitle: location.subTitle,
        // color: location.color,
        url: location.imageUrl,
        anchorOffset: new DOMPoint(-16, -16),
        clusteringIdentifier: location.highlight? 'bierbutler_cluster' : 'cluster_id',
        collisionMode: 'rectangle',
        displayPriority: 1,
        callout: annotationCallout
      };
    }
  }

  function _setCenter(locations) {
    const center = locations.filter(function (value) {
      return value.type === 'center'
    });

    if (center.length === 0) {
      return;
      // return setCenterToLocation({latitude: 52.087852, longitude: 5.126885});
    }
    setCenterToLocation(center[0])
  }

  function hasLocations() {
    return cachedLocations.length > 0
  }

  function setCenterToLocation(location, zoom) {
    setCenterToCoordinates(location.latitude, location.longitude, zoom)
  }

  function setCenterToCoordinates(latitude, longitude, zoom) {
    const desiredZoom = zoom || 0.5
    const span = new mapkit.CoordinateSpan(desiredZoom, desiredZoom);
    const center =  new mapkit.Coordinate(latitude, longitude);
    const region = new mapkit.CoordinateRegion(center, span);
    map.setRegionAnimated(region);
  }

  var initSearch = function () {
    search = new mapkit.Search({ language: 'nl', region: searchRegion()});
    return search;
  };

  function searchRegion() {
    const searchCenter = new mapkit.Coordinate(52.134610, 5.538797);
    const searchSpan = new mapkit.CoordinateSpan(1.2, 1.2);
    const searchRegion = new mapkit.CoordinateRegion(searchCenter, searchSpan);
    return searchRegion;
  }

  var searchForLocations = function (search, input, zoom, callback) {
    search.region = searchRegion();
    search.search(input, function (error, data) {
      if (error) {
        callback({ success: false, closestLocations: [] });
      } else {
        var firstMatch = data.places[0];

        var distances = cachedLocations.map(function (location) {
          return {
            location: location,
            distance: getDistanceFromCoordinatesInKm(location, firstMatch.coordinate)
          }
        }).sort(function (a, b) {
          return a.distance - b.distance;
        });

        if (distances[0].distance < SEARCH_THRESHOLD) {
          setCenterToLocation(distances[0].location);
          return callback({ success: true, closestLocations: distances });
        } else {
          var possibleResults = searchCachedLocationOnName(input);
          if (possibleResults && possibleResults.length > 0) {
            setCenterToLocation({longitude: possibleResults[0].longitude, latitude: possibleResults[0].latitude}, zoom);
          } else {
            setCenterToCoordinates(firstMatch.coordinate.latitude, firstMatch.coordinate.longitude, zoom);
          }
          return callback({ success: false, closestLocations: distances });
        }
      }
    });
  };

  var searchCachedLocationOnName = function(search) {
    const sanitizedSearch = search.toLowerCase();
      return cachedLocations.filter(function(location){
        return location.title.toLowerCase().includes(sanitizedSearch);
      });
  };

  var getDistanceFromCoordinatesInKm = function (coordinate, coordinate2) {
    return getDistanceFromLatLonInKm(coordinate.latitude, coordinate.longitude, coordinate2.latitude, coordinate2.longitude)
  };

  var getDistanceFromLatLonInKm = function (lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Distance in km
    return distance;
  };

  function deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  return {
    init: init, //shortcut for initMapKit + initMap + addMarkers
    initMapKit: initMapKit,
    initMap: initMap,
    addMarkers: addMarkers,
    setCenterToLocation: setCenterToLocation,
    initSearch: initSearch,
    searchForLocations: searchForLocations,
    hasLocations: hasLocations,
    getDistanceFromLatLonInKm: getDistanceFromLatLonInKm
  };
})();

export default mapModule;
