import ErrorHandler from "./errors";
import CookieStorage from "../polyfills/cookie";

const Storage = (function(){

  let tmpStorage = {}

  function isAvailable(){
    if (localStorage) {
      return true;
    }

    return false;
  }

  function getItem(key) {
    try {
      if (localStorage) {
        return localStorage.getItem(key)
      } else {
        return CookieStorage.getCookie(key)
      }
    } catch (ex) {
      ErrorHandler.captureException(ex);
      return getFromTmp(key);
    }
  }

  function getFromTmp(key) {
    return tmpStorage[key] == undefined ? null : tmpStorage[key]
  }

  function setItem(key, flag) {
    storeItem(key, flag);
  }

  function storeObject(key, data) {
    storeItem(key, JSON.stringify(data));
  }

  function storeItem(key, data) {
    try {
      if (localStorage) {
        localStorage.setItem(key, data);
      } else {
        return CookieStorage.setCookie(key, data)
      }
    } catch(ex) {
      ErrorHandler.captureException(ex);
      tmpStorage[key] = data;
    }
  }

  return {
    getItem: getItem,
    setItem: setItem,
    storeObject: storeObject,
    isAvailable: isAvailable
  }
})();

export default Storage;
