import FileService from "./file-service";

const FileUploadPlaceholder = (function () {
  "use strict";

  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    Array.from(document.querySelectorAll('[data-file-upload]')).forEach(fileUpload => {
      handleFileUpload(fileUpload);
    })
  }

  function handleFileUpload(fileUpload) {
    const inputElement = fileUpload.type == 'file' ? fileUpload : fileUpload.querySelector('input')
    inputElement.removeEventListener('change', onFileInputChanged)
    inputElement.addEventListener('change', onFileInputChanged)
  }

  function onFileInputChanged(event) {
    FileService.showPreview(event)
  }

  return {
    init: init
  }
})();

export default FileUploadPlaceholder
