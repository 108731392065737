import FileService from "./file-service";

const FileUpload = (function () {

  function init() {
    const fileUploadElements = Array.from(document.querySelectorAll('.file_input'));
    fileUploadElements.forEach(element => {
      const inputElement = element.querySelector('input');
      const displayLabel = element.querySelector('label');
      const placeholderText = inputElement.getAttribute('placeholder');
      displayLabel.innerText = placeholderText ? placeholderText : 'Selecteer bestand';

      inputElement.removeEventListener('change', handleFileInputChanged);
      inputElement.addEventListener('change', handleFileInputChanged);
    });
  }

  function handleFileInputChanged(event) {
    const inputElement = event.currentTarget;
    const value = inputElement.value
    const length = value.split("\\").length;
    const displayLabel = inputElement.parentElement.querySelector('label');
    displayLabel.innerText = length > 0 ? value.split("\\")[length - 1] : value;

    if (inputElement.dataset.preview) {
      FileService.showPreview(event)
    }
  }

  return {
    init: init
  }
})();

export default FileUpload;
