import Api from "../ajax/api";
import Helper from "../polyfills/helper";

(function () {
  function init(){
    document.removeEventListener("ajax::refresh", init);
    document.addEventListener("ajax::refresh", init);

    clearInterval(window.bannerInterval)
    window.bannerInterval = setInterval(handleBanners, 6000)

    enableTracking();
  }

  function handleBanners(){
    const randomBannerElements = document.querySelectorAll('[data-random-banner="1"]')
    Array.from(randomBannerElements).forEach(function(bannerElement){
      handleBannerElement(bannerElement)
    })
  }

  function handleBannerElement(bannerElement){
    const bannerIds = JSON.parse(bannerElement.querySelector('input').value)
    let bannerIndex = bannerElement.dataset.currentBannerIndex;

    console.log('old banner index', bannerIndex);
    if (!bannerIndex) {
      bannerIndex = Math.floor(Math.random() * bannerIds.length) + 0
    } else {
      bannerIndex = parseInt(bannerIndex) + 1;
      if (bannerIndex >= bannerIds.length) {
        bannerIndex = 0
      }
    }
    console.log('new banner index', bannerIndex);

    bannerElement.dataset.currentBannerIndex = bannerIndex
    const url = '/blocks/' + bannerElement.dataset.blockId + '/banners?banner_id=' + bannerIds[bannerIndex]
    const request = new Request(url);
    Api.callApi(request, 'GET').then(data => {
      bannerElement.querySelector('[data-banner-target]').innerHTML = data
      enableTracking(bannerElement)
    })
  }

  function enableTracking(bannerElement){
    if (bannerElement) {
      bannerElement.querySelectorAll('[data-view]').forEach((banner) => {
        fetch("https://newsfeed.martini-online.nl/banners/" + banner.dataset.externalId + "/view?tick=" + Date.now()).then((data) => {
        });
      })
    } else {
      document.querySelectorAll('[data-view]').forEach((banner) => {
        fetch("https://newsfeed.martini-online.nl/banners/" + banner.dataset.externalId + "/view?tick=" + Date.now()).then((data) => {
        });
      })
    }

    document.querySelectorAll('[data-click]').forEach((banner) => {
      banner.removeEventListener('click', bannerClicked)
      banner.addEventListener('click', bannerClicked)
    })
  }

  function bannerClicked(event){
    const banner = event.currentTarget;
    fetch("https://newsfeed.martini-online.nl/banners/" + banner.dataset.externalId + "/click?tick=" + Date.now()).then((data) => {
    });
  }

  document.addEventListener('DOMContentLoaded', function () {
    init();
  })
})();
