import Helper from "../polyfills/helper";

const Collapse = (function () {

  function init() {
    Helper.handleCustomEvent('ajax::refresh', init)

    const collapseTogglers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'))
    collapseTogglers.forEach(element => {
      element.removeEventListener('click', onCollapseTogglerClicked)
      element.addEventListener('click', onCollapseTogglerClicked)
    })
  }

  function onCollapseTogglerClicked(event) {
    const element = event.currentTarget;
    collapseTogglerClicked(element);
  }

  function collapseTogglerClicked(element) {
    const target = document.querySelector(element.dataset.target);

    if (target.dataset.toggleClass) {
      target.classList.toggle(target.dataset.toggleClass)
    } else {
      target.classList.toggle('flex')
    }

    if (element.dataset.parent) {
      element.closest(element.dataset.parent).classList.toggle('expanded')
    } else {
      element.classList.toggle('expanded')
    }
  }

  return {
    init: init
  }

})();

export default Collapse;
