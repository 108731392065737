const CookieStorage = (function () {
  function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return match[2];
    } else {
      return null;
    }
  }

  function setCookie(name, value) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 365 * 10);
    document.cookie = encodeURIComponent(name)
      + "=" + encodeURIComponent(value)
      + "; expires=" + expirationDate.toUTCString();

    // document.cookie = name + "=" + value;
  }

  function setCookieWithExpiration(name, value, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    document.cookie = encodeURIComponent(name)
      + "=" + encodeURIComponent(value)
      + "; expires=" + expirationDate.toUTCString();
  }

  return {
    setCookie: setCookie,
    setCookieWithExpiration: setCookieWithExpiration,
    getCookie: getCookie
  }
})();

export default CookieStorage;
