import CookieStorage from "./polyfills/cookie";

document.addEventListener('DOMContentLoaded', function () {
  if (hasCheckedCookie()) {
    return;
  }

  const cookieBanner = document.querySelector('[data-cookie-banner]');
  if (!cookieBanner) {
    return;
  }

  cookieBanner.querySelectorAll('[data-accept-all]').forEach(function (button) {
    button.removeEventListener('click', onCookieConsent)
    button.addEventListener('click', onCookieConsent)
  })


  cookieBanner.querySelectorAll('[data-accept-selected]').forEach(function (button) {
    button.removeEventListener('click', onCookiePartialConsent)
    button.addEventListener('click', onCookiePartialConsent)
  })

  function onCookieConsent(event) {
    CookieStorage.setCookie('cookie_consent', 'all')
    location.reload();
  }

  function onCookiePartialConsent(event) {
    const performance = document.querySelector('#performance_cookies').checked;
    const targeting = false;

    const consent = performance && targeting ? "all" : performance ? "per" : targeting ? "tar" : "none";
    CookieStorage.setCookie('cookie_consent', consent)
    location.reload();
  }

  function hasCheckedCookie() {
    return CookieStorage.getCookie('cookie_consent') != null
  }
});
