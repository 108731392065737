import ErrorHandler from "../polyfills/errors";
import Api from "../ajax/api";
import Helper from "../polyfills/helper";

(function () {
  function init(){
    document.removeEventListener("ajax::refresh", handleRemoteLinks);
    document.addEventListener("ajax::refresh", handleRemoteLinks);

    handleRemoteLinks();
  }

  function handleRemoteLinks(){
    document.querySelectorAll('[data-remote-link]').forEach(likeElement => {
      likeElement.removeEventListener('click', onRemoteLinkClicked)
      likeElement.addEventListener('click', onRemoteLinkClicked)
    })
  }

  function onRemoteLinkClicked(event){
    const target = event.currentTarget;
    const url = target.dataset.url;

    const request = new Request(url);

    Api.callApi(request, target.dataset.method, {}).then(data => {
      target.innerHTML = data;
    }).catch(ex => {
      ErrorHandler.captureException(ex)
      debugger
    })
  }

  document.addEventListener('DOMContentLoaded', function () {
    init();
  })
})();
