// ErrorHandler.captureException(new Error("data: " + JSON.stringify(errorData)))
// import * as Sentry from "@sentry/browser";

// Sentry.init({
//   // dsn: 'https://22f9d90c8fa3484da68a107c74e3de33@sentry.lico.nl/98',
//   // integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1,
// });

// window.Sentry = Sentry

const ErrorHandler = (function(){

  function captureException(ex) {
    try {
      // Sentry.captureException(ex);
    } catch(ex){

    }
  }

  return {
    captureException: captureException
  }
})();

export default ErrorHandler;
