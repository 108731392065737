import Helper from "../polyfills/helper";

const Share = (function () {

  function init() {
    Helper.handleCustomEvent('ajax::refresh', init)
    if (navigator.share) {
      enableShare()
      const shareButtons = document.querySelectorAll('#share_button');
      shareButtons.forEach(function(shareButton) {
        handleShareButton(shareButton)
      });
    }
  }

  function enableShare() {
    const shareButtons = document.querySelectorAll('#share_button');
    Array.from(shareButtons).forEach(function(shareButton) {
      handleShareButton(shareButton);
      shareButton.classList.remove('hidden')
    });
  }

  function handleShareButton(shareButton) {
    shareButton.addEventListener('click', event => {
      if (navigator.share) {
        navigator.share({
          title: shareButton.dataset.title,
          url: shareButton.dataset.url
        }).then(() => {
        }).catch(console.error);
      }
    });
  }

  return {
    init: init
  }
})();

export default Share;
