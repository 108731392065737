// import DropDown from "./dropdown";
// import LazyLoader from "./lazy-loaders";
// import Tab from "./tabs";
// import Cloner from "./clone"
// import ClientSideSearch from "./client-side-search";
import Modal from "./modal";
import Collapse from "./collapse";
import Share from "./share";
import Dropdown from "./dropdown";
// import HelpIcon from "./help-icon";
// import Share from "./share";
// import Popup from "./popup";
// import {Slider} from "./slider";
// import Wizard from "./wizard";
//
document.addEventListener("DOMContentLoaded", function () {
//   DropDown.init();
//   LazyLoader.init();
//   Tab.init();
//   Cloner.init();
//   ClientSideSearch.init();
  Modal.init();
  Collapse.init();
  Share.init()
  Dropdown.init()
//   HelpIcon.init();
//   Share.init();
//   Popup.init();
//   Wizard.init();
//   // Slider.init()
});
