import Form from "./form";
import Switch from "./switch";
import FileUpload from "./files/file-upload";
import FileUploadPlaceholder from "./files/file_upload_placeholder";

document.addEventListener("DOMContentLoaded", function() {
  Form.init();
  Switch.init();
  FileUpload.init();
  FileUploadPlaceholder.init();
});
