import Helper from "../polyfills/helper";

const Dropdown = (function () {

  function init() {
    Array.from(document.querySelectorAll('.dropdown')).forEach(element => {
      element.querySelector('[data-toggle]').removeEventListener('click', handleDropdownItemClick)
      element.querySelector('[data-toggle]').addEventListener('click', handleDropdownItemClick)
    })

    document.body.removeEventListener('click', hideDropdowns)
    document.body.addEventListener('click', hideDropdowns)
  }

  function handleDropdownItemClick(event) {
    const toggler = event.currentTarget;
    const dropdownElement = toggler.closest('.dropdown');
    toggleDropdown(dropdownElement, !dropdownElement.classList.contains('show'))
    event.preventDefault();
    event.stopPropagation();
    return true;
  }

  function toggleDropdown(dropdownElement, active) {
    const toggler = dropdownElement.querySelector('[data-toggle]')
    const dropdownMenuElement = dropdownElement.querySelector('.dropdown-menu')
    if (active) {
      toggler.classList.add('show');
      dropdownMenuElement.classList.add('show')
    } else {
      toggler.classList.remove('show');
      dropdownMenuElement.classList.remove('show')
    }
  }

  function hideDropdowns(event) {
    const dropdownItem = event.target.closest('.dropdown');
    if (dropdownItem) {
      return;
    }

    const dropdownItems = Array.from(document.querySelectorAll('.dropdown'));
    dropdownItems.forEach(element => {
      toggleDropdown(element, false)
    })
  }

  return {
    init: init
  }

})();
export default Dropdown;
