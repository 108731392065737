const Vacancy = (function () {

  function init() {
    const searchBar = document.querySelector('[data-search]');
    if (searchBar) {
      const listIcon = searchBar.querySelector('.show-icon-list');
      const gridIcon = searchBar.querySelector('.show-icon-grid');

      if (listIcon) { listIcon.addEventListener('click', onListClicked); }
      if (gridIcon) { gridIcon.addEventListener('click', onGridClicked); }
    }
  }

  function onListClicked(event) {
    const target = event.currentTarget;
    target.closest('.show-icons').querySelector('.show-icon-grid').classList.remove('show-icon-active');
    target.classList.add('show-icon-active');

    document.querySelector('[data-previews]').classList.remove('block-grid')
  }

  function onGridClicked(event) {
    const target = event.currentTarget;
    target.closest('.show-icons').querySelector('.show-icon-list').classList.remove('show-icon-active');
    target.classList.add('show-icon-active');

    document.querySelector('[data-previews]').classList.add('block-grid')
  }

  return {
    init: init
  }
})();

export default Vacancy;
