import ErrorHandler from "../polyfills/errors";
import Api from "../ajax/api";
import Helper from "../polyfills/helper";

(function () {
  let options = {
    threshold: 0.3
  }

  let observer = new IntersectionObserver(callback, options);

  function callback(entries, observer) {
    handleEntries(entries)
    Helper.dispatchEvent('ajax::success', {})
    Helper.dispatchEvent('ajax::refresh', {})
  }

  function handleEntries(entries){
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        if (entry.target.dataset.article) {
          if (entry.target.dataset.article != window.location.href) {
            history.pushState({}, "page 2", entry.target.dataset.article);
          }
        } else {
          observer.unobserve(entry.target);
          // const currentArticle = entry.target.closest('[data-article]')

          const markers = document.querySelectorAll('[data-marker]');
          const count = markers.length;
          if (count >= 1) {
            const previewElements = document.querySelectorAll('[data-article-preview]')
            const previewElement = previewElements[0];

            if (previewElement) {
              const url = previewElement.dataset.articlePreview;
              const request = new Request(url);

              return Api.callApi(request, 'GET').then(data => {

                const parentElement = previewElement.parentElement;
                parentElement.innerHTML = data;

                // window.setTimeout(function () {
                const marker = parentElement.querySelector('[data-marker]')
                observer.observe(marker);

                const currentArticle = marker.closest('[data-article]')
                if (currentArticle) {
                  observer.observe(currentArticle);
                }

                window.fullScreenImage.init();
                handleArticleNewSessionLink();
                // }, 100);
              }).catch(ex => {
                ErrorHandler.captureException(ex)
                debugger
              })
            }
          }
        }
      }
    });
  }

  function init() {
    showUserSuggestions();
    handleArticleNewSessionLink();
    let marker = document.querySelector('[data-marker]');
    if (marker) {
      observer.observe(marker);

      const currentArticle = marker.closest('[data-article]')
      if (currentArticle) {
        observer.observe(currentArticle);
      }
    }
  }

  function handleArticleNewSessionLink(){
    const links =document.querySelectorAll('[data-new-session-from-comment-link]')
    links.forEach(function(link){
      const reactionInput = link.closest('.new-reaction').querySelector('textarea')
      if (reactionInput){
        reactionInput.addEventListener('change', handleReactionInputChange)
        reactionInput.addEventListener('change', handleReactionInputChange)
      }
    })
  }

  function showUserSuggestions(){
    const request = new Request('/user_related_articles');
    Api.callApi(request, "GET").then((result) => {
      if (result.suggested_articles) {
        result.suggested_articles.forEach(suggested_article => {
          const article = document.querySelector('[data-article-id="' + suggested_article + '"]')
          if (article){
            article.classList.add('is--suggested')
          }
        })
      }
    });
  }

  function handleReactionInputChange(event){
    const target = event.currentTarget;
    const reactionInput = target.closest('.new-reaction')
    if (reactionInput){
      const link = reactionInput.querySelector('[data-new-session-from-comment-link]')
      if (link){
        if (!link.dataset.href) {
          link.dataset.href = link.href
        }

        link.href = link.dataset.href + '&comment=' + target.value;
      }
    }
  }

  document.addEventListener('DOMContentLoaded', function () {
    init();
  })
})();
